var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Alert", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "To generate a dialog we have the global function $vs.dialog the parameters to generate the dialog are"
        ),
      ]),
      _c(
        "vs-list",
        [
          _c("vs-list-item", {
            attrs: {
              title: "title",
              subtitle: "title of the dialogue - String",
            },
          }),
          _c("vs-list-item", {
            attrs: {
              title: "text",
              subtitle: "internal text of the dialog - String",
            },
          }),
          _c("vs-list-item", {
            attrs: {
              title: "color",
              subtitle: "color of the component - Colors, RGB, HEX",
            },
          }),
          _c("vs-list-item", {
            attrs: {
              title: "accept",
              subtitle:
                "function that executes the user accept the dialog - Function",
            },
          }),
          _c("vs-list-item", {
            attrs: {
              title: "cancel",
              subtitle:
                "function that executes the user to cancel the dialog (if it is a confirm type) - Function",
            },
          }),
          _c("vs-list-item", {
            attrs: {
              title: "type",
              subtitle:
                "determines the type of dialog (alert or confirm) - alert, confirm",
            },
          }),
          _c("vs-list-item", {
            attrs: {
              title: "buttonAccept",
              subtitle: "determines the type of accept button - Boolean",
            },
          }),
          _c("vs-list-item", {
            attrs: {
              title: "buttonCancel",
              subtitle: "determines the type of cancel button - Boolean",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.openAlert("primary")
                },
              },
            },
            [_vm._v("Alert Primary")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "success", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.openAlert("success")
                },
              },
            },
            [_vm._v("Alert Success")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "danger", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.openAlert("danger")
                },
              },
            },
            [_vm._v("Alert Danger")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "warning", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.openAlert("warning")
                },
              },
            },
            [_vm._v("Alert Warning")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "dark", type: "flat" },
              on: {
                click: function ($event) {
                  return _vm.openAlert("dark")
                },
              },
            },
            [_vm._v("Alert Dark")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "gradient" },
              on: {
                click: function ($event) {
                  return _vm.openAlert("#FF6F91")
                },
              },
            },
            [_vm._v("Alert Color RGB | HEX")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-button @click="openAlert(\'primary\')" color="primary" type="flat">Alert Primary</vs-button>\n  <vs-button @click="openAlert(\'success\')" color="success" type="flat">Alert Success</vs-button>\n  <vs-button @click="openAlert(\'danger\')" color="danger" type="flat">Alert Danger</vs-button>\n  <vs-button @click="openAlert(\'warning\')" color="warning" type="flat">Alert Warning</vs-button>\n  <vs-button @click="openAlert(\'dark\')" color="dark" type="flat">Alert Dark</vs-button>\n  <vs-button @click="openAlert(\'#FF6F91\')" color="primary" type="gradient">Alert Color RGB | HEX</vs-button>\n</template>\n\n<script>\nexport default {\n  data:()=>({\n    colorAlert:\'primary\',\n    titleAlert:\'Alert\',\n    activeAlert:false,\n    valueInput:\'\',\n  }),\n  methods:{\n    openAlert(color){\n      this.colorAlert = color\n      this.$vs.dialog({\n        color:this.colorAlert,\n        title: `Dialog - ${this.colorAlert}`,\n        text: \'I love soufflé lollipop liquorice wafer jelly-o halvah sesame snaps. Pastry chocolate cake jelly-o carrot cake jelly topping croissant ice cream.\',\n        accept:this.acceptAlert\n      })\n    },\n    acceptAlert(){\n      this.$vs.notify({\n        color:this.colorAlert,\n        title:\'Accept Selected\',\n        text:\'Gingerbread soufflé biscuit oat cake.\'\n      })\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }