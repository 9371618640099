var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "dialog-demo" } },
    [_c("dialogs-alert"), _c("dialogs-confirm"), _c("dialogs-prompt")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }