var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Prompt", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "To add a dialog of type prompt we have a global function a completely independent component "
        ),
        _c("code", [_vm._v("vs-prompt")]),
        _vm._v(
          ". This allows more flexibility with the input and ability to add any type of input and components"
        ),
      ]),
      _c(
        "div",
        { staticClass: "my-3" },
        [
          _c("span", [
            _vm._v(
              "The properties of the component are equivalent to those of the global function only with the vs before each property something like"
            ),
          ]),
          _c("vx-list", {
            attrs: { list: ["function: color", "component = vsColor"] },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "border" },
              on: {
                click: function ($event) {
                  _vm.activePrompt = true
                },
              },
            },
            [_vm._v("Run prompt")]
          ),
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "border" },
              on: {
                click: function ($event) {
                  _vm.activePrompt2 = true
                },
              },
            },
            [_vm._v("Run prompt inputs")]
          ),
          _c("div", { staticClass: "op-block" }, [
            _vm._v(
              "Security Code: " + _vm._s(_vm.val == null ? "null" : _vm.val)
            ),
          ]),
          _c("div", { staticClass: "op-block" }, [
            _vm._v(
              "\n        Name: " +
                _vm._s(_vm.valMultipe.value1) +
                " |\n        Last Name: " +
                _vm._s(_vm.valMultipe.value2) +
                "\n      "
            ),
          ]),
        ],
        1
      ),
      _c(
        "vs-prompt",
        {
          attrs: { active: _vm.activePrompt },
          on: {
            cancel: function ($event) {
              _vm.val = ""
            },
            accept: _vm.acceptAlert,
            close: _vm.close,
            "update:active": function ($event) {
              _vm.activePrompt = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "con-exemple-prompt" },
            [
              _c("span", [_vm._v("Enter the security code")]),
              _c("vs-input", {
                staticClass: "mt-3 w-full",
                attrs: { placeholder: "Code", "vs-placeholder": "Code" },
                model: {
                  value: _vm.val,
                  callback: function ($$v) {
                    _vm.val = $$v
                  },
                  expression: "val",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-prompt",
        {
          attrs: { "is-valid": _vm.validName, active: _vm.activePrompt2 },
          on: {
            cancel: _vm.clearValMultiple,
            accept: _vm.acceptAlert,
            close: _vm.close,
            "update:active": function ($event) {
              _vm.activePrompt2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "con-exemple-prompt" },
            [
              _vm._v("\n        Enter your first and last name to "),
              _c("b", [_vm._v("continue")]),
              _vm._v(".\n      "),
              _c("vs-input", {
                staticClass: "mt-4 mb-2 w-full",
                attrs: { placeholder: "Name" },
                model: {
                  value: _vm.valMultipe.value1,
                  callback: function ($$v) {
                    _vm.$set(_vm.valMultipe, "value1", $$v)
                  },
                  expression: "valMultipe.value1",
                },
              }),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { placeholder: "Last Name" },
                model: {
                  value: _vm.valMultipe.value2,
                  callback: function ($$v) {
                    _vm.$set(_vm.valMultipe, "value2", $$v)
                  },
                  expression: "valMultipe.value2",
                },
              }),
              _c(
                "vs-alert",
                {
                  staticClass: "mt-4",
                  attrs: {
                    active: !_vm.validName,
                    color: "danger",
                    "vs-icon": "new_releases",
                  },
                },
                [
                  _vm._v(
                    "\n        Fields can not be empty please enter the data\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n    <div class="demo-alignment">\n      <vs-button @click="activePrompt = true" color="primary" type="border">Run prompt</vs-button>\n      <vs-button @click="activePrompt2 = true" color="primary" type="border">Run prompt inputs</vs-button>\n      <div class="op-block">Security Code: ' +
            _vm._s("{{ val == null ? 'null' : val }}") +
            ' </div>\n      <div class="op-block">\n        Name: ' +
            _vm._s("{{ valMultipe.value1 }}") +
            " | Last Name: " +
            _vm._s("{{ valMultipe.value2 }}") +
            '\n      </div>\n\n    </div>\n\n    <vs-prompt\n      @cancel="val=\'\'"\n      @accept="acceptAlert"\n      @close="close"\n      :active.sync="activePrompt">\n      <div class="con-exemple-prompt">\n        <span>Enter the security code</span>\n      <vs-input placeholder="Code" vs-placeholder="Code" v-model="val" class="mt-3 w-full" />\n      </div>\n    </vs-prompt>\n\n    <vs-prompt\n      @cancel="clearValMultiple"\n      @accept="acceptAlert"\n      @close="close"\n      :is-valid="validName"\n      :active.sync="activePrompt2">\n      <div class="con-exemple-prompt">\n        Enter your first and last name to <b>continue</b>.\n      <vs-input placeholder="Name" v-model="valMultipe.value1" class="mt-4 mb-2 w-full" />\n      <vs-input placeholder="Last Name" v-model="valMultipe.value2" class="w-full" />\n\n      <vs-alert :vs-active="!validName" color="danger" vs-icon="new_releases" >\n        Fields can not be empty please enter the data\n      </vs-alert>\n      </div>\n    </vs-prompt>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      activePrompt:false,\n      activePrompt2:false,\n      val:\'\',\n      valMultipe:{\n        value1:\'\',\n        value2:\'\'\n      },\n    }\n  },\n  computed:{\n    validName(){\n      return (this.valMultipe.value1.length > 0 && this.valMultipe.value2.length > 0)\n    }\n  },\n  methods:{\n    acceptAlert(){\n      this.$vs.notify({\n        color:\'success\',\n        title:\'Accept Selected\',\n        text:\'Lorem ipsum dolor sit amet, consectetur\'\n      })\n    },\n    close(){\n      this.$vs.notify({\n        color:\'danger\',\n        title:\'Closed\',\n        text:\'You close a dialog!\'\n      })\n    },\n    clearValMultiple() {\n      this.valMultipe.value1 = "";\n      this.valMultipe.value2 = "";\n    }\n  }\n}\n</script>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }